<template>
  <div>
    <page-header title='Certificados professores'></page-header>
    <v-row justify="end" class="mb-0">
      <v-btn color="white" depressed rounded class="mx-2 mr-10 mt-n3" @click="$router.push({name: 'ConcludedCourseCertificates'})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-arrow-left-bold-circle-outline</v-icon> 
        CERTIFICADO ALUNOS
      </v-btn>
      <v-btn color="white" depressed rounded class="mx-2 mr-10 mt-n3" @click="$router.push({name: 'CreateTeacherCertificate'})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-plus-circle-outline</v-icon> 
        GERAR CERTIFICADO
        <router-view></router-view>
      </v-btn>
    </v-row>

    <!-- <v-card style="border-radius: 10px" flat class="pb-3 mt-3"> -->
    <v-card style="border-radius:12px" flat outlined class="mt-3">
      <v-card-title primary-title class='primary white--text py-1' >
        <div class="d-flex flex-column">
          <div class="text-subtitle-2">Curso</div>
          <div style="word-break: break-word">{{ course.name }}</div>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <card-loading-transparent v-if="loading" class="pt-3"></card-loading-transparent>
      <v-card-text v-if="!loading" class="mb-n3 pa-0 pb-1">
        <v-data-table
          :headers="headers"
          :items-per-page="10"
          :items="items"
          :search="search"
        >
          <template v-slot:top>
            <v-text-field  v-model="search" label="Buscar" class="mx-4"></v-text-field>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small text color="primary" @click="openCertificate(item)" rounded outlined>
              <v-icon left>mdi-clipboard-text</v-icon>Abrir certificado
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <dialog-loading v-if="loading_certificate"></dialog-loading>
    <dialog-show-file 
      v-if="!loading_certificate" 
      title="Certificado" 
      @closeDialog="handleCloseDialog" 
      :dialog_file="dialog && !loading_certificate" 
      file_kind="application/pdf" 
      :file_url="file_url"
    ></dialog-show-file>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Api from '@/api/index';
import validations from '@/mixins/validations';

export default {
  name: 'TeacherCertificates',
  components: {
    CreateTeacherCertificateDialog: () => import ('./CreateTeacherCertificateDialog.vue'),
  },
  mixins: [
    validations
  ],
  props: {
    user_id: {
      type: Number,
      defalt: null
    }
  },
  data() {
    return { 
      items: [],
      loading: false,
      dialog: false,
      file_url: null,
      loading_certificate: false,
      search: '',
      headers: [
        { text: '#', value: 'id'},
        { text: 'Professores' ,value: 'teacher_name'},
        { text: 'Certificado', value: 'actions', align: 'center', sortable: false},
      ],
      course: {}
    }
  },
  mounted () {
    this.getTeacherCertificates()
    this.getCourseInfo()
  },
  methods: {
    handleCloseDialog(){
      this.dialog = false
    },
    getCourseInfo(){
      Api.Course.show(this.$route.params.id).then(r=>r.data).then(d=>{
        this.course = d
      });
    },
    openCertificate(item){
      this.file_url = item.certificate_file_url;
      this.dialog = true;
    },
    certificateDialog(item){
      this.file_url = item.certificate_file_url
      this.dialog = true
    },
    getTeacherCertificates(){
      this.loading = true
      Api.TeacherCertificates.index({course_id: this.$route.params.id}).then(r => r.data).then(r => {
        this.items = r
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false
      })
    },
    ...mapActions({
      addNotification: 'Notification/add',
    })
  },
  computed: {
    ...mapState({
      user: state=> state.User.user,
    })
  },
  watch: {
    '$route.params'(newValue, oldValue) {
      if(newValue.success){
        this.getTeacherCertificates()
      }
    }
  },
}
</script>

<style>
</style>